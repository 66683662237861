:root {
    --background: #ffffff;
    --text: #282c34;
    --accent-bright: #950740;
    --position: translateX(0px);
}

[theme='dark'] {
    --background: #282c34;
    --text: #ffffff;
    --accent-bright: #d11e66;
    --position: translateX(38px);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.app {
    background-color: var(--background);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: var(--text);
}

.app-link {
    padding: 0.5rem;
    color:var(--accent-bright);
}

.label {
    display: flex;
    align-items: center;
}

.checkbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: var(--accent-bright);
    width: 80px;
    height: 40px;
    border-radius: 50px;
    padding: 5px;
    cursor: pointer;
   
}
  
.checkbox-toogle {
    background-color: #fff;
    width: 38px;
    height: 38px;
    position: absolute;
    left: 0px;
    border-radius: 50%;
    transition: transform 0.2s linear;
    transform: var(--position);
}

.icon-light {
    padding: 0.5rem;
    color: yellow;
}

.icon-dark {
    padding: 0.5rem;
    color: silver;
}
  